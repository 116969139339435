define("hc2-ui/components/apps/sweepstake/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content", "hc2-ui/helpers/input-pattern", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _hasHtmlContent, _inputPattern, _globalAppTranslations) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        appData: {},
        editorResources: {},

        validationPattern: (0, _inputPattern.patternList)(),

        appStates: {
            showWelcomePage: false
        },
        editableSubAppLabels: (0, _globalAppTranslations.getEditableSubAppLabels)("sweepstake"),
        editableLabels: (0, _globalAppTranslations.getEditableLabels)("sweepstake"),

        lotPriceSelectorOptions: {
            min: 0,
            max: 10000,
            step: 0.01,
            decimals: 2,
            boostat: 5,
            maxboostedstep: 10,
            postfix: '€'
        },

        selectedQuestion: Ember.computed.alias("appStates.selectedQuestion"),

        save: "save",
        actions: {
            save: function save() {
                this.sendAction('save', this.get('appData'));
            },
            setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
                this.set('appData.headerImageUri', url);
            },
            handleError: function handleError(err) {
                alert("Error:\n" + err);
            },
            selectParticipationPage: function selectParticipationPage() {
                this.switchWelcomePageIfActive(false);
                this.set("selectedQuestion", this.get('appData.questions.length'));
            },
            selectQuestionsPage: function selectQuestionsPage() {
                this.switchWelcomePageIfActive(false);
                if (this.get('selectedQuestion') === this.get('appData.questions.length')) {
                    this.set("selectedQuestion", 0);
                }
            },
            selectQuestion: function selectQuestion(index) {
                this.set("selectedQuestion", index);
            },
            addQuestion: function addQuestion() {
                this.get('appData.questions').pushObject({ answers: [] });
                this.set("selectedQuestion", this.get('appData.questions.length') - 1);
            },
            removeQuestion: function removeQuestion(questionIndex) {
                this.get('appData.questions').removeAt(questionIndex);
                this.set("selectedExtraFromElement", 0);
            },
            addAnswer: function addAnswer(question) {
                question.answers.pushObject(Object.create({ label: "", correct: false }));
                this.get("appData").notifyPropertyChange("contactData");
            },
            removeAnswer: function removeAnswer(question, answerIndex) {
                question.answers.removeAt(answerIndex);
            },
            toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
                this.switchWelcomePageIfActive(display);
            },
            setCountDownDuration: function setCountDownDuration(on) {
                this.set("appData.countDownDuration", on ? 60 : 0);
            }
        },

        observeWelcomePage: function () {
            this.switchWelcomePageIfActive(true);
        }.observes("appData.welcomePage"),
        switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
            this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
        },

        didRender: function didRender() {
            _init.default.material(this.elementId);
        },
        showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
            return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
        }),
        setupModel: function () {
            this.set("selectedQuestion", 0);

            if (!this.get('appData.questions')) {
                this.set('appData.questions', Ember.A([]));
            }
            if (!this.get('appData.contactData')) {
                this.set('appData.contactData', {
                    salutationMandetory: true,
                    nameMandetory: true,
                    firstName: true,
                    firstNameMandetory: true,
                    lastName: true,
                    lastNameMandetory: true,
                    email: true,
                    emailMandetory: true,
                    extraFromElements: []
                });
            }
        }.on("init"),

        didInsertElement: function didInsertElement() {
            //this.$(".sweepstake-editor-form > .nav li:nth-child(5) a").click();
        }
    });
});