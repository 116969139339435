define('hc2-ui/router', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('test');
    this.route('external-content');
    this.route('login');
    this.route('login-user', { path: '/login-user/:username' });
    this.route('sign-up');
    this.route('logout');
    this.route('password-forgot');
    this.route('password-change', { path: '/password-forgot/:token' });
    this.route('app', function () {
      this.route('view', { path: '/view/:app_type/:app_id' });
      this.route('privacy', { path: '/privacy/:app_id/:lang' });
      this.route('conditions', { path: '/conditions/:app_id/:lang' });
    });
    this.route('backend', function () {
      this.route('apps', function () {
        this.route('create', { path: '/create/:appType_id' });
        this.route('copy-for-user', { path: '/copyForUser/:app_type/:app_id' });
        this.route('move-to-user', { path: '/moveToUser/:app_type/:app_id' });
        this.route('edit', { path: '/edit/:app_type/:app_id' });
        this.route('mine');
        this.route('customers');
        this.route('allplatforms');
        this.route('details', { path: "/details/:app_type/:app_id" });
      });
      this.route('integrations');
      this.route('mailer');
      this.route('platforms');
      this.route('platform', { path: "/platform/:platform_id" });
      this.route('user', { path: "/user/:user_id" });
    });
  });

  exports.default = Router;
});