define('hc2-ui/services/tracking', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    headData: Ember.inject.service(),
    api: Ember.inject.service(),
    config: Ember.inject.service(),
    embed: Ember.inject.service(),

    // public tracking functions
    trackPage: function trackPage() {
      this.sendTransition();
      if (this.get("trackingEnabled")) {
        this.sendGaPage();
        this.sendFacebookPageView();
      }
    },
    sendExternalLinkEvent: function sendExternalLinkEvent(url) {
      if (this.get("trackingEnabled")) {
        this.sendGaExternalLinkEvent(url);
      }
    },
    sendLeadEvent: function sendLeadEvent(label) {
      this.sendInternalLeadEvent(label);
      if (this.get("trackingEnabled")) {
        this.sendFacebookLead();
        this.sendGaLeadEvent(label);
      }
    },
    initLinkTracking: function initLinkTracking() {
      var _this = this;

      Ember.$(".track-links a").each(function (i, a) {
        if (!a.onclick) {
          a.onclick = function () {
            _this.sendExternalLinkEvent(a.href);
          };
        }
      });
    },


    // Google Analytics implementations
    sendGaExternalLinkEvent: function sendGaExternalLinkEvent(url) {
      if (window.ga !== undefined && this.get("trackingEnabledGA")) {
        window.ga('clientTracker.send', 'event', 'outbound', 'click', url);
        window.ga('send', 'event', 'outbound', 'click', url);
      }
    },

    // Google Analytics implementations
    sendGaLeadEvent: function sendGaLeadEvent(label) {
      if (window.ga !== undefined && this.get("trackingEnabledGA")) {
        if (label) {
          window.ga('clientTracker.send', 'event', 'Forms', 'Lead', label);
          window.ga('send', 'event', 'Forms', 'Lead', label);
        } else {
          window.ga('clientTracker.send', 'event', 'Forms', 'Lead');
          window.ga('send', 'event', 'Forms', 'Lead');
        }
      }
    },
    getPageTitle: function getPageTitle() {
      var title = this.get("headData.title") || window.hc2InitialAppTitle;
      if (this.get("appendToTitle")) {
        title += this.get("appendToTitle");
      }
      return title;
    },
    sendGaPage: function sendGaPage() {
      if (window.ga !== undefined && this.get("trackingEnabledGA")) {
        var page = {
          'location': location.href,
          'title': this.getPageTitle()
        };
        window.ga('send', 'pageview', page);
      }
    },
    sendTransition: function sendTransition() {
      var e = {
        'type': 'pageview',
        'url': location.href,
        'title': this.getPageTitle()
      };
      this.get('api').post("/event", e, true);
      if (this.get("embed.isEmbedded")) {
        this.get("embed").notifyEvent(e);
      }
    },
    sendInternalLeadEvent: function sendInternalLeadEvent(label) {
      var appId = this.get("config.platform.appId");
      if (appId) {
        appId = " [" + appId + "]";
      }
      this.get('api').post("/event", {
        'type': 'event',
        'e_c': 'participation',
        'e_a': 'participation submit',
        'e_l': label + appId,
        'url': location.href,
        'title': this.getPageTitle(),
        'rid': _environment.default.rid
      }, true);
      if (this.get("embed.isEmbedded")) {
        this.get("embed").notifyEvent({
          'type': 'participation',
          'url': location.href,
          'title': this.getPageTitle()
        });
      }
    },
    sendEmbedEvent: function sendEmbedEvent(type, category, label) {
      if (this.get("embed.isEmbedded")) {
        this.get("embed").notifyEvent({
          'type': type,
          'category': category,
          'label': label,
          'url': location.href,
          'title': this.getPageTitle()
        });
      }
    },


    // Facebook Pixel implementation
    initFacebookPixel: function initFacebookPixel(pixelId) {
      !function (win, doc, tagName, src, n, t, s) {
        if (win.fbq) return;

        n = win.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };

        if (!win._fbq) win._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = doc.createElement(tagName);
        t.async = !0;
        t.src = src;
        s = doc.getElementsByTagName(tagName)[0];
        s.parentNode.insertBefore(t, s);
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', pixelId);
    },
    sendFacebookPageView: function sendFacebookPageView() {
      if (window.fbq && this.get("trackingEnabledFB")) window.fbq('track', 'PageView');
    },
    sendFacebookLead: function sendFacebookLead() {
      if (window.fbq && this.get("trackingEnabledFB")) window.fbq('track', 'Lead');
    },


    clientFacebookPixelId: null,
    optInNeeded: false,
    trackingEnabled: false,
    trackingEnabledGA: false,
    trackingEnabledFB: false,

    trackingOptOut: function trackingOptOut() {
      window.gaOptout();
      this.set("trackingEnabled", false);
    },
    trackingOptIn: function trackingOptIn() {
      window.gaOptin();
      this.initTracking();
    },
    initThirdPartyScripts: function initThirdPartyScripts(tracking, addToAny, facebook, recaptcha) {
      if (tracking) {
        this.initTracking();
      }
      if (addToAny) {
        this.loadAddToAny();
      }
      if (facebook) {
        this.loadFacebookSDK();
      }
      if (recaptcha) {
        this.loadRecaptcha();
      }
    },
    initTracking: function initTracking() {

      if (this.get("optInNeeded") && !window["trackingOptedIn"]) {
        return false;
      } else {
        var trackingMode = 'gtm';
        if (!window.ga && !window.gaDisabled() && window.gaProperty && trackingMode === 'ga') {
          (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o), m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
          })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

          if (this.get("embed.isEmbedded")) {
            this.setupGaTrackerWaitingForEmbedHostClientId(0, 8000);
          } else {
            this.setupGaTracker();
          }
        }
        if (!window.ga && !window.gaDisabled() && window.gaProperty && trackingMode === 'gtm') {

          this.loadExternalScript("https://www.googletagmanager.com/gtag/js?id=" + window.gaProperty, 'gtm');

          window.dataLayer = window.dataLayer || [];
          window.gtag = function () {
            window.dataLayer.push(arguments);
          };
          window.gtag('js', new Date());
          window.gtag('config', window.gaProperty);
        }
        // set up client Google Analytics Tracker
        if (this.get("clientFacebookPixelId")) {
          this.initFacebookPixel(this.get("clientFacebookPixelId"));
          this.set("trackingEnabledFB", true);
        }
        this.set("trackingEnabled", true);
      }
    },

    gaClientId: null,
    setupGaTrackerWaitingForEmbedHostClientId: function setupGaTrackerWaitingForEmbedHostClientId(loop, maxWait) {
      var _this2 = this;

      var wait = 500;
      if (!this.get("gaClientId") && loop * wait < maxWait) {
        console.log("waiting for embedHostGaClientId", loop);
        Ember.run.later(function () {
          _this2.setupGaTrackerWaitingForEmbedHostClientId(++loop, maxWait);
        }, wait);
      } else {
        this.setupGaTracker();
        if (this.get("firstTransitionDone")) {
          this.sendGaPage(true);
        }
      }
    },
    setupGaTracker: function setupGaTracker() {
      var gaSettings = {
        allowLinker: true
      };
      if (this.get("gaClientId")) {
        gaSettings['clientId'] = this.get("gaClientId");
      }
      gaSettings['cookieFlags'] = 'secure;samesite=none';
      window.ga('create', window.gaProperty, 'auto', gaSettings);
      window.ga('set', 'anonymizeIp', true);
      window.ga(function () {
        console.log("ga in embed initialized with clientId", window.gaProperty, window.ga.getAll()[0].get('clientId'), gaSettings);
      });
      this.set("trackingEnabledGA", true);
    },
    loadAddToAny: function loadAddToAny() {
      //this.loadExternalScript("//static.addtoany.com/menu/page.js", "addToAny");
    },
    loadRecaptcha: function loadRecaptcha() {
      //this.loadExternalScript("https://www.google.com/recaptcha/api.js?render=explicit", "recaptcha");
    },
    loadFacebookSDK: function loadFacebookSDK() {
      //this.loadExternalScript("//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.5&appId=519321034913139", 'facebook-jssdk');
    },
    loadExternalScript: function loadExternalScript(src, id) {
      console.log("loading " + src);
      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = src;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', id);
    }
  });
});