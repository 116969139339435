define("hc2-ui/routes/sign-up", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    afterModel: function afterModel(model) {
      this.set("pageTitle", [this.get("i18n").t("register.header")]);
    },
    model: function model() {
      return this.modelFor("application");
    }
  });
});