define("hc2-ui/components/apps/game/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _hasHtmlContent, _globalAppTranslations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    games: Ember.inject.service(),
    appData: {},
    editorResources: {},

    editableSubAppLabels: (0, _globalAppTranslations.getEditableSubAppLabels)("game"),
    editableLabels: (0, _globalAppTranslations.getEditableLabels)("game"),

    save: "save",
    actions: {
      save: function save() {
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setPairBackgroundImageFromUpload: function setPairBackgroundImageFromUpload(url) {
        this.set('appData.cardBackgroundImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("appStates.selectedTab", "participate");
        this.set("appStates.selectedPage", 1);
      },
      selectPairsPage: function selectPairsPage() {
        this.switchWelcomePageIfActive(false);
        this.set("appStates.selectedTab", "participate");
        this.set("appStates.selectedPage", 0);
      },
      selectPair: function selectPair(index) {
        this.set("selectedPair", index);
      },
      addPair: function addPair() {
        this.get('appData.pairs').pushObject({});
        this.set("selectedPair", this.get('appData.pairs.length') - 1);
      },
      removePair: function removePair(index) {
        this.get('appData.pairs').removeAt(index);
        this.set("selectedPair", 0);
      },
      setPairImageFromUpload: function setPairImageFromUpload(url, pair) {
        Ember.set(pair, 'image', url);
      },
      setPairImage2FromUpload: function setPairImage2FromUpload(url, pair) {
        Ember.set(pair, 'image2', url);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject(Object.create({ label: "", correct: false }));
        this.get("appData").notifyPropertyChange("contactData");
      },
      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
    },
    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),
    setupModel: function () {
      this.set("appStates.selectedPage", 0);
      this.set("selectedPair", 0);

      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init"),

    didInsertElement: function didInsertElement() {
      //this.$(".sweepstake-editor-form > .nav li:nth-child(4) a").click();
    }
  });
});